<template>
  <div style="background-color: #f6f6f6">
    <div class="fill-width align-self-start">
      <v-card
        class="d-flex align-center fill-width"
        color="primary"
        flat
        height="50"
        tile
      >
        <a class="pa-5" href="https://testapp.io">
          <img
            :src="
              fromExpress
                ? '/media/logos/logo_express.png'
                : '/media/logos/logo_light.svg'
            "
            alt
            height="30"
          />
        </a>
      </v-card>
    </div>
    <v-container>
      <v-row v-if="loading" justify="center">
        <v-col v-for="i in 4" :key="i" class="mb-4" cols="12" md="8">
          <v-skeleton-loader
            type="list-item-avatar, divider, list-item-three-line, card-heading, image"
          ></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="errorMessage" class="mb-15" justify="center">
        <v-col cols="12" md="8">
          <v-card class="pa-10">
            <template>
              <v-col class="text-center titleFont bold-text" cols="12">
                Ops!
              </v-col>
              <v-col cols="12">
                <v-alert color="red" label outlined>
                  <span v-html="errorMessage"></span>
                </v-alert>
              </v-col>
              <v-col class="text-center" cols="12">
                Please contact the person who shared this link with you for more
                info
              </v-col>
              <v-col class="text-center" cols="12">
                <img
                  class="mx-auto my-auto"
                  src="/media/error/not_available.png"
                  width="260"
                />
              </v-col>
            </template>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="mb-15" justify="center">
        <v-col cols="12" md="8">
          <v-row
            v-if="
              isAuthenticated &&
              canManageApp &&
              installInfo.screenshots.length === 0
            "
          >
            <v-col cols="12">
              <v-alert color="info" dismissible outlined>
                <span
                  ><v-icon>mdi-lightbulb-on-outline</v-icon> Customize this page
                  from the Settings page</span
                >
                <span class="ml-2">
                  <VueTooltip
                    :v-html="'This message is only visible to the app managers of this app. <br /><br />Add description, screenshots, and other info from App -> Share -> Settings page'"
                    color="primary"
                    icon-color="grey"
                    text-class="white--text"
                  />
                </span>
              </v-alert>
            </v-col>
          </v-row>
          <v-card class="px-8" flat>
            <v-row
              v-if="installInfo.settings.status"
              class="pb-0"
              justify="center"
            >
              <v-col class="pb-0" cols="12" md="12">
                <v-alert
                  :color="
                    installInfo.settings.status == 'coming_soon'
                      ? 'primary'
                      : 'purple'
                  "
                  :icon="
                    installInfo.settings.status == 'coming_soon'
                      ? 'mdi-clock'
                      : 'mdi-alert'
                  "
                  outlined
                >
                  <div>
                    <span v-if="!installInfo.settings.custom_message">
                      {{
                        get(installInfo, "settings.status", null) ==
                        "coming_soon"
                          ? "This app will be soon available for public testing!"
                          : "This app is in maintenance mode, please check back later"
                      }}
                    </span>
                    <span v-else>
                      <div>
                        {{ installInfo.settings.custom_message }}
                        <sub>
                          <small
                            >~
                            {{
                              get(
                                installInfo,
                                "settings.developer_contact.name",
                                installInfo.name + " app developers"
                              )
                            }}</small
                          >
                        </sub>
                      </div>
                    </span>
                  </div>
                </v-alert>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="12">
                <v-row align="center">
                  <v-col cols="auto" md="8">
                    <v-row align="center">
                      <v-col cols="auto">
                        <img
                          :alt="installInfo.name"
                          :src="getAppImage()"
                          height="100"
                          width="100"
                          @error="replaceDefaultAppImage"
                        />

                        <!-- <AppImage
                          :appId="installInfo.image"
                          :image="getAndroidReleaseInfo.image"
                          :size="$vuetify.breakpoint.mdAndUp ? '99' : '60'"
                          :defaultImg="'/media/defaults/icon-100.png'"
                          class="d-flex justify-center align-center"
                        /> -->
                      </v-col>
                      <v-col cols="auto">
                        <div
                          :class="$vuetify.breakpoint.mdAndUp ? 'mb-1' : ''"
                          class="font-size-h2 bold-text"
                        >
                          {{ installInfo.name }}
                        </div>
                        <div
                          v-if="
                            get(
                              installInfo,
                              'settings.developer_contact.name',
                              null
                            )
                          "
                        >
                          <span class="text-muted">By</span>
                          <span>
                            {{
                              get(
                                installInfo,
                                "settings.developer_contact.name",
                                "-"
                              )
                            }}
                          </span>
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col
                    v-if="mobile === 'unknown'"
                    class="text-center my-auto px-auto"
                    cols="12"
                    lg="4"
                    md="4"
                    sm="4"
                  >
                    <div v-if="!noReleases">
                      <!-- <v-btn
                        @click="qrcodeModal=true"
                        class="text-transform-none text-h5"
                        color="primary"
                        block
                        large
                      >
                        Install
                      </v-btn> -->

                      <v-btn
                        block
                        color="primary"
                        x-large
                        @click="qrcodeModal = true"
                      >
                        <v-icon
                          left
                          v-text="'mdi-cellphone-arrow-down'"
                        ></v-icon>
                        Install
                      </v-btn>

                      <div class="mt-2 text-muted">
                        Available for
                        {{
                          getIOSReleaseInfo && getAndroidReleaseInfo
                            ? "both Android & iOS"
                            : getIOSReleaseInfo
                            ? "iOS"
                            : "Android"
                        }}
                      </div>
                    </div>
                    <div v-else>
                      <img
                        :src="
                          get(installInfo, 'settings.status', null)
                            ? '/media/error/maintenance.png'
                            : '/media/error/not_available.png'
                        "
                        class="errImg"
                      />
                      <div class="mt-2 text-muted">No available releases</div>
                    </div>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-if="
                      installInfo.categories && installInfo.categories.length
                    "
                    class="pt-0 mt-0"
                    cols="12"
                    md="8"
                  >
                    <v-chip
                      v-for="(item, index) in installInfo.categories"
                      :key="index"
                      class="mr-1 mt-0"
                      x-small
                    >
                      {{ item.name }}
                    </v-chip>
                  </v-col>
                  <v-col cols="12" md="8">
                    <vue-readmore
                      :length="200"
                      :text="getAppDescription()"
                    ></vue-readmore>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col v-if="noReleases && mobile === 'unknown'" cols="12">
                <div class="text-right mt-3">
                  <div
                    v-if="
                      mobile === 'unknown' &&
                      (getIOSReleaseInfo || getAndroidReleaseInfo)
                    "
                  >
                    <div v-if="!getIOSReleaseInfo || !getAndroidReleaseInfo">
                      Available for
                      {{ getIOSReleaseInfo ? "iOS" : "Android" }}
                      <v-menu bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            class="text-transform-none mb-2 cursor-pointer"
                            icon
                            small
                            text
                            v-bind="attrs"
                            v-on="on"
                          >
                            <v-icon small v-text="'mdi-help-circle'"></v-icon>
                          </v-btn>
                        </template>
                        <v-card class="pa-4" width="300">
                          <v-row>
                            <v-col class="text-center" cols="12">
                              <v-row justify="center">
                                <v-col cols="2">
                                  <v-icon
                                    left
                                    v-text="'mdi-information-outline'"
                                  ></v-icon>
                                </v-col>
                                <v-col
                                  class="text-left black--text"
                                  cols="auto"
                                >
                                  This release is only available <br />
                                  for
                                  {{ getIOSReleaseInfo ? "iOS" : "Android" }}
                                </v-col>
                              </v-row>
                            </v-col>
                            <!--                            <v-col cols="12">-->
                            <!--                              <v-divider></v-divider>-->
                            <!--                            </v-col>-->
                            <!--                            <v-col class="text-center" cols="12">-->
                            <!--                              <v-btn-->
                            <!--                                @click="showRequestForm = true"-->
                            <!--                                class="text-transform-none"-->
                            <!--                                color="primary"-->
                            <!--                                depressed-->
                            <!--                              >-->
                            <!--                                Request a new release for-->
                            <!--                                {{ getIOSReleaseInfo ? "Android" : "iOS" }}-->
                            <!--                              </v-btn>-->
                            <!--                            </v-col>-->
                          </v-row>
                        </v-card>
                      </v-menu>
                    </div>
                    <!--                    <div-->
                    <!--                      v-if="!getIOSReleaseInfo || !getAndroidReleaseInfo"-->
                    <!--                      @click="showRequestForm = true"-->
                    <!--                      class="-->
                    <!--                        primary&#45;&#45;text-->
                    <!--                        text-decoration-underline-->
                    <!--                        cursor-pointer-->
                    <!--                      "-->
                    <!--                    >-->
                    <!--                      Update me when available for-->
                    <!--                      {{ getIOSReleaseInfo ? "Android" : "iOS" }}-->
                    <!--                    </div>-->
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="
                mobile === 'ios' &&
                !getIOSReleaseInfo &&
                !get(installInfo, 'settings.status', null)
              "
            >
              <v-col class="font-size-h3 bold-text text-center">
                This release isn’t available for your platform (iOS) yet
              </v-col>

              <v-col v-if="getAndroidReleaseInfo" class="text-center" cols="12">
                If you have Android device, use it to open this page or scan the
                below QR code
                <figure class="qrcode">
                  <qrcode
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 200,
                    }"
                    :value="shareLink"
                  ></qrcode>
                  <img
                    :alt="installInfo.name"
                    :src="getAppImage()"
                    class="qrcode__image"
                    @error="replaceDefaultAppImage"
                  />
                </figure>
              </v-col>
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <div-->
              <!--                  @click="showRequestForm = true"-->
              <!--                  class="primary&#45;&#45;text cursor-pointer"-->
              <!--                >-->
              <!--                  Update me when available on iOS-->
              <!--                </div>-->
              <!--              </v-col>-->
            </v-row>
            <v-row
              v-if="
                mobile === 'android' &&
                !getAndroidReleaseInfo &&
                !get(installInfo, 'settings.status', null)
              "
            >
              <v-col class="font-size-h3 bold-text text-center">
                This release isn’t available for your platform (Android) yet
              </v-col>
              <v-col v-if="getIOSReleaseInfo" class="text-center" cols="12">
                If you have iOS device, use it to open this page or scan the
                below QR code
                <figure class="qrcode">
                  <qrcode
                    :options="{
                      errorCorrectionLevel: 'Q',
                      width: 200,
                    }"
                    :value="shareLink"
                  ></qrcode>
                  <img
                    :alt="installInfo.name"
                    :src="getAppImage()"
                    class="qrcode__image"
                    @error="replaceDefaultAppImage"
                  />
                </figure>
              </v-col>
              <!--              <v-col class="text-center" cols="12">-->
              <!--                <div-->
              <!--                  @click="showRequestForm = true"-->
              <!--                  class="primary&#45;&#45;text cursor-pointer"-->
              <!--                >-->
              <!--                  Update me when available on Android-->
              <!--                </div>-->
              <!--              </v-col>-->
            </v-row>
            <v-row v-if="mobile == 'unknown'" align="center">
              <v-col class="text-center" cols="12">
                <div class="text-muted small">
                  Having trouble installing? Read our
                  <span
                    class="text-decoration-underline primary--text cursor-pointer body-1"
                    @click="scrollToFAQ"
                  >
                    FAQ
                  </span>
                </div>
              </v-col>
              <v-col class="text-center" cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row
              v-if="
                (mobile !== 'unknown' &&
                  mobile === 'ios' &&
                  getIOSReleaseInfo) ||
                (mobile === 'android' && getAndroidReleaseInfo)
              "
            >
              <v-col class="text-center" cols="12">
                <v-btn
                  :color="
                    mobile == 'android'
                      ? 'success lighten-1'
                      : 'primary lighten-1'
                  "
                  :disabled="downloadProcess"
                  :loading="busy"
                  x-large
                  @click="startDownload"
                >
                  <v-icon
                    left
                    v-text="mobile == 'android' ? 'mdi-android' : 'mdi-apple'"
                  ></v-icon>
                  {{ downloadProcess ? "Downloading..." : "Download" }}
                </v-btn>
              </v-col>
              <v-col class="text-center" cols="12">
                <div class="text-muted small">
                  Having trouble installing? Read our
                  <span
                    class="text-decoration-underline primary--text body-1 cursor-pointer"
                    @click="scrollToFAQ"
                  >
                    FAQ
                  </span>
                </div>
              </v-col>
            </v-row>
            <v-row
              v-if="installInfo.screenshots && installInfo.screenshots.length"
            >
              <v-col cols="12">
                <div class="screenShotContainer">
                  <div
                    v-for="(item, index) in installInfo.screenshots"
                    :key="index"
                    style="display: flex"
                  >
                    <v-sheet class="imageBorder mr-2">
                      <v-img
                        :src="item"
                        cover
                        height="500"
                        style="border-radius: 20px"
                        width="250"
                      ></v-img>
                    </v-sheet>
                  </div>
                </div>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col
                v-if="
                  (mobile === 'unknown' &&
                    (getAndroidReleaseInfo || getIOSReleaseInfo)) ||
                  (mobile === 'android' && getAndroidReleaseInfo) ||
                  (mobile === 'ios' && getIOSReleaseInfo)
                "
                cols="12"
                md="12"
              >
                <div class="font-size-h2 mb-3 bold-text">Instructions</div>
                <vue-readmore
                  :length="200"
                  :text="installInfo.instructions || '-'"
                ></vue-readmore>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="mobile === 'ios' && getIOSReleaseInfo"
                cols="12"
                md="12"
              >
                <div class="font-size-h2 bold-text">Release Update</div>
                <vue-readmore
                  :length="200"
                  :text="getIOSReleaseInfo.note || '-'"
                ></vue-readmore>
              </v-col>
              <v-col
                v-if="mobile === 'android' && getAndroidReleaseInfo"
                cols="12"
                md="12"
              >
                <div class="font-size-h2 bold-text">Release Update</div>
                <vue-readmore
                  :length="200"
                  :text="getAndroidReleaseInfo.note || '-'"
                ></vue-readmore>
              </v-col>
            </v-row>
            <v-row v-if="!noReleases" justify="center">
              <v-col class="py-10" cols="10">
                <v-divider></v-divider>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-if="['android', 'unknown'].includes(mobile)"
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="bodyFont text-muted">
                      <v-icon
                        color="grey"
                        right
                        size="20"
                        v-text="'mdi-android'"
                      ></v-icon>

                      Android
                    </div>
                  </v-col>
                  <v-col v-if="!getAndroidReleaseInfo" cols="12">
                    Not available
                  </v-col>
                  <template v-else>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Store</div>
                      <div
                        v-if="
                          get(installInfo, 'settings.store_links.android', '')
                        "
                      >
                        <a
                          :href="
                            get(installInfo, 'settings.store_links.android', '')
                          "
                        >
                          <MaxText
                            :text="
                              get(
                                installInfo,
                                'settings.store_links.android',
                                ''
                              )
                            "
                            max="18"
                          />
                        </a>
                      </div>
                      <div v-else>-</div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text">Package</div>
                        <div>
                          <MaxText
                            :text="getAndroidReleaseInfo.package"
                            max="15"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Version</div>
                        <div>
                          <MaxText
                            :text="getAndroidReleaseInfo.version"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Version Code</div>
                        <div>
                          <MaxText
                            :text="getAndroidReleaseInfo.version_code"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Size</div>
                      <div>
                        {{ formatBytes(getAndroidReleaseInfo.size) }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Released</div>
                        <div>
                          <vue-hoverable-date
                            :date="getAndroidReleaseInfo.created_at"
                          ></vue-hoverable-date>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Minimum SDK Version</div>
                      <div>
                        {{
                          get(getAndroidReleaseInfo, "info.min_sdk_version", "")
                        }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Target SDK Version</div>
                      <div>
                        {{
                          get(
                            getAndroidReleaseInfo,
                            "info.target_sdk_version",
                            ""
                          )
                        }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Permissions</div>
                      <div
                        v-if="
                          getAndroidReleaseInfo &&
                          getAndroidReleaseInfo.info &&
                          getAndroidReleaseInfo.info.permissions &&
                          getAndroidReleaseInfo.info.permissions.length
                        "
                        class="primary--text cursor-pointer"
                        @click="showPermissionDialog"
                      >
                        View permissions
                      </div>
                      <div v-else>-</div>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
              <v-col
                v-if="['ios', 'unknown'].includes(mobile)"
                cols="12"
                md="6"
              >
                <v-row>
                  <v-col cols="12">
                    <div class="bodyFont text-muted">
                      <v-icon
                        class="mb-1"
                        color="grey"
                        right
                        size="20"
                        v-text="'mdi-apple'"
                      ></v-icon>

                      iOS
                    </div>
                  </v-col>
                  <v-col v-if="!getIOSReleaseInfo" cols="12">
                    Not available
                  </v-col>
                  <template v-else-if="getIOSReleaseInfo.testflight">
                    <v-col>
                      <a :href="getIOSReleaseInfo.testflight"> Click here </a>
                      to start testing in TestFlight
                    </v-col>
                  </template>
                  <template v-else>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Store</div>
                      <div
                        v-if="get(installInfo, 'settings.store_links.ios', '')"
                      >
                        <a
                          :href="
                            get(installInfo, 'settings.store_links.ios', '')
                          "
                        >
                          <MaxText
                            :text="
                              get(installInfo, 'settings.store_links.ios', '')
                            "
                            max="18"
                          />
                        </a>
                      </div>
                      <div v-else>-</div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text">Package</div>
                        <div>
                          <MaxText :text="getIOSReleaseInfo.package" max="15" />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Version</div>
                        <div>
                          <MaxText :text="getIOSReleaseInfo.version" max="18" />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Version Code</div>
                        <div>
                          <MaxText
                            :text="getIOSReleaseInfo.version_code"
                            max="18"
                          />
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div class="bold-text mb-2">Size</div>
                      <div>
                        {{ formatBytes(getIOSReleaseInfo.size) }}
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Released</div>
                        <div>
                          <vue-hoverable-date
                            :date="getIOSReleaseInfo.created_at"
                          ></vue-hoverable-date>
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Supported Platforms</div>
                        <div
                          v-for="(item, sindex) in get(
                            getIOSReleaseInfo,
                            'info.supported_platforms',
                            []
                          )"
                          :key="sindex"
                        >
                          {{ item }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Minimum OS Version</div>
                        <div>
                          {{
                            get(
                              getIOSReleaseInfo,
                              "info.minimum_os_version",
                              ""
                            )
                          }}
                        </div>
                      </div>
                    </v-col>
                    <v-col cols="6">
                      <div>
                        <div class="bold-text mb-2">Platform Version</div>
                        <div>
                          {{
                            get(getIOSReleaseInfo, "info.platform_version", "-")
                          }}
                        </div>
                      </div>
                    </v-col>
                  </template>
                </v-row>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
              <v-col cols="12">
                <div class="bodyFont bold-text text-muted">General Info</div>
              </v-col>
              <v-col cols="6" md="4">
                <div class="bold-text mb-2">Developer</div>
                <div class="mb-2">
                  {{ get(installInfo, "settings.developer_contact.name", "") }}
                </div>
                <div class="mb-2">
                  {{ get(installInfo, "settings.developer_contact.email", "") }}
                </div>
                <div>
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.website', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.website', '')
                    "
                  >
                    Website
                  </a>
                </div>
              </v-col>
              <v-col cols="6" md="4">
                <div class="bold-text mb-2">App Legal</div>
                <div
                  v-if="
                    get(installInfo, 'settings.developer_contact.terms', '')
                  "
                  class="mb-2"
                >
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.terms', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.terms', '')
                    "
                  >
                    Terms & Conditions
                  </a>
                </div>
                <div
                  v-if="
                    get(installInfo, 'settings.developer_contact.privacy', '')
                  "
                >
                  <a
                    v-if="
                      get(installInfo, 'settings.developer_contact.privacy', '')
                    "
                    :href="
                      get(installInfo, 'settings.developer_contact.privacy', '')
                    "
                  >
                    Privacy policy
                  </a>
                </div>
                <div
                  v-if="
                    !get(
                      installInfo,
                      'settings.developer_contact.privacy',
                      ''
                    ) &&
                    !get(installInfo, 'settings.developer_contact.terms', '')
                  "
                >
                  Not provided
                </div>
              </v-col>
            </v-row>
            <v-row id="releaseFaq" class="mt-8 faq" justify="center">
              <v-col class="questions" cols="12" lg="12">
                <h1 class="font-size-h2 bold-text">FAQ</h1>
                <br />
                <v-expansion-panels flat>
                  <template v-if="['android', 'unknown'].includes(mobile)">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app on Android, I get a security
                          warning
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          On Android 8 or higher, you get a dialog such as
                          <b
                            >"Chrome, For your security, your phone is not
                            allowed to install unknown apps from this source"</b
                          >. <br /><br />
                          Click the <b>Settings</b> button of that dialog and
                          toggle <b>Allow from this source</b>. <br /><br />

                          Alternatively you can navigate to
                          <b>Phone Settings > Apps & notifications</b>.
                          <br /><br />
                          Find the browser application in the list, tap
                          <b>Advanced</b> then <b>Install unknown apps</b> then
                          toggle <b>Allow from this source</b>. <br /><br />
                          On Android 7 or lower, navigate to the device
                          <b>Settings</b> then into the
                          <b>Security</b> category. Under the
                          <b>Device Administration</b> category, toggle
                          <b>Unknown Sources</b>. Then try to install the
                          application again. Please note that some device
                          manufacturers might customize the settings UI and thus
                          menu entries can have different names or be at a
                          different location.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app, I get a "There Was a Problem
                          Parsing the Package" error
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          This error could be caused by one of the following
                          reasons:
                          <br />
                          <br />
                          <ul>
                            <li>File may be downloaded incompletely</li>
                            <li>
                              Application might be not suitable for your
                              hardware or OS version
                            </li>
                            <li>Security settings</li>
                            <li>Corrupted APK file</li>
                            <li>
                              Name of the package is changed after signing
                            </li>
                          </ul>
                          <br />
                          <p>
                            To troubleshoot, try reinstalling the app. If that
                            doesn't work, contact the app developer to help
                            resolve the issue.
                          </p>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                  <template v-if="['ios', 'unknown'].includes(mobile)">
                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          I get the message “Untrusted Enterprise Developer”
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          In order for the app to be available for use, you will
                          need to trust the developer's certificate.
                          <br /><br />
                          From your phone’s home screen, tap
                          <b
                            >Settings > General > Profiles or Profiles & Device
                            Management</b
                          >. <br />Under the <b>Enterprise App</b> heading, you
                          see will see a profile for the developer. Tap the name
                          of the developer profile and then confirm you trust
                          them. You should now be able to launch the app.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          While installing the app, I get an “Unable to Download
                          App” error, and the app is not installed
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          This error could be caused by many reasons, and you
                          should contact the app developer to help resolve the
                          issue.<br /><br />
                          If you are the app developer, this error could be
                          caused by one of the following reasons: <br />

                          <ul>
                            <li>
                              UDID is not included in the provisioning profile
                            </li>
                            <li>
                              The build was incorrectly signed or has broken
                              entitlements
                            </li>
                            <li>
                              Device or iOS version is incompatible with the
                              build
                            </li>
                            <li>Device storage is full</li>
                            <li>Actual download error</li>
                            <li>
                              App is over 50 MB and downloaded over a cellular
                              connection
                            </li>
                          </ul>
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          After installing the app, It appears to download
                          successfully but I am unable to open it
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          It could be because your UDID is missingg from
                          provisioning profile. <br />

                          Go to <a href="/udid">install UDID page</a> in safari
                          and continue from there
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>

                    <v-expansion-panel>
                      <v-expansion-panel-header class="py-2 px-4">
                        <div class="font-size-h3 font-weight-light">
                          When I press the “Install” button, the installation
                          alert never shows up and the app never installs on my
                          device
                        </div>
                        <template #actions>
                          <v-icon class="ml-4" large left>expand_more</v-icon>
                        </template>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div>
                          If you have previously installed the app from the App
                          Store, and it carries the same version of the app that
                          is being installed, there will be no alert to confirm
                          the installation.<br /><br />
                          As a workaround, please try uninstalling the existing
                          version of the app, and then tap the Install button
                          again.
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </template>
                </v-expansion-panels>
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>

      <Modal v-model="qrcodeModal" @close="qrcodeModal = false">
        <template #message>
          <install-qr-code
            :android-release-info="getAndroidReleaseInfo"
            :image="getAppImage()"
            :install-info="installInfo"
            :ios-release-info="getIOSReleaseInfo"
            :share-link="shareLink"
            from-modal
          ></install-qr-code>
        </template>
      </Modal>
      <Modal
        v-model="openPermissionModal"
        :fullscreen="$vuetify.breakpoint.smAndDown"
        width="600"
        @close="openPermissionModal = false"
      >
        <template #message>
          <v-row v-if="openPermissionModal" justify="center">
            <v-col class="mt-4 text-center" cols="12">
              <div class="titleFont bold-text">
                {{ installInfo.name }}
              </div>
              <div class="mt-4 bodyFont">
                <span class="text-muted mr-2">By</span>
                <span>{{
                  get(installInfo, "settings.developer_contact.name", "")
                }}</span>
              </div>
            </v-col>
            <v-col class="black--text scrollable" cols="12">
              <permission-mapper
                :permissions="getAndroidReleaseInfo.info.permissions"
              ></permission-mapper>
            </v-col>
          </v-row>
        </template>
      </Modal>
      <Modal v-model="showRequestForm" @close="showRequestForm = false">
        <template #message>
          <release-request-form
            :android="getAndroidReleaseInfo"
            :ios="getIOSReleaseInfo"
          ></release-request-form>
        </template>
      </Modal>
      <Modal v-model="openSubscribeModal" @close="openSubscribeModal = false">
        <template #message>
          <subscribe-form
            v-if="openSubscribeModal"
            @close="openSubscribeModal = false"
            @success="subscribedMessage = $event"
          ></subscribe-form>
        </template>
      </Modal>
      <Modal v-model="openIosInstallHelp">
        <template #message>
          <v-card-title class="font-weight-bold"
            >Progress in background
          </v-card-title>
          <v-card-text class="message">
            Once you tap on install button, you can check the progress in your
            <b>home screen</b>.
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="main primary" @click="openIosInstallHelp = false">
              Ok
            </v-btn>
          </v-card-actions>
        </template>
      </Modal>
      <Modal
        v-model="androidInstallConfirmation"
        @close="androidInstallConfirmation = false"
      >
        <template #card>
          <v-card>
            <v-card-title>
              <div class="bold-text subListFont">
                Do you trust this developer?
              </div>
              <v-spacer></v-spacer>
              <v-btn icon @click="androidInstallConfirmation = false"></v-btn>
            </v-card-title>
            <v-card-text>
              <div class="bodyFont darkGrey--text">
                TestApp.io did not scan this application from viruses or
                malware.<br /><br />

                If you <b>trust this developer</b>, please go ahead and install
                this application.<br /><br />

                TestApp.io is not responsible for any damage or harm that this
                application may case to you or your device.<br /><br />

                That doesn't mean that this application is harmful, we just want
                to make sure that you are aware of the risks.
                <hr class="my-4" />

                <b>So... what is TestApp.io?</b> <br /><br />
                It's a platform that allows developers to easily share their
                applications with anyone for testing and feedback before
                releasing to Android & Apple stores.
                <br /><br />TestApp.io is not a replacement for the Android &
                Apple stores and does not own this application. <br /><br />
                For more info, please contact the person who shared this link
                with you.
              </div>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                class="text-transform-none"
                color="primary"
                text
                @click="androidInstallConfirmation = false"
              >
                Cancel
              </v-btn>
              <v-btn
                class="text-transform-none"
                color="primary"
                @click="setConfirmationInStorage"
              >
                Okay, got it!
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </Modal>
      <v-dialog v-model="showPasswordModal" fullscreen hide-overlay persistent>
        <v-card color="primary">
          <v-toolbar color="primary" dark flat>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-card-text>
            <v-row align="center" class="mt-15" justify="center">
              <v-col class="text-center" cols="12" md="3">
                <div class="titleFont white--text mb-5">
                  {{ passwordInformation.message }}
                </div>
                <v-card class="pa-4">
                  <v-row class="darkGrey--text">
                    <v-col cols="12">
                      <v-avatar height="80" tile width="80">
                        <v-img
                          :src="
                            passwordInformation.image || '/media/logos/lock.svg'
                          "
                          contain
                        ></v-img>
                      </v-avatar>
                    </v-col>
                    <v-col class="titleFont" cols="12">
                      {{ passwordInformation.name }}
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-model="protectedPassword"
                        v-validate="'required|max:30|min:3'"
                        counter="30"
                        filled
                        label="Enter your password"
                        type="password"
                        v-bind="veeValidate('Password', 'Enter your password')"
                      ></v-text-field>
                    </v-col>
                    <v-col class="text-center" cols="12">
                      <v-btn
                        :disabled="$validator.errors.any()"
                        :loading="verifyPasswordLoading"
                        block
                        class="text-transform-none pa-7"
                        color="primary"
                        @click="verifyPassword"
                        @keypress.enter="verifyPassword"
                      >
                        <div class="font14">Submit</div>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-card>
                <div class="mt-4 bodyFont white--text"></div>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions class="mt-15 pt-15">
            <v-spacer></v-spacer>
            <div class="text-center white--text">
              <v-img
                class="mb-3 mx-auto"
                contain
                height="20"
                src="/media/logos/logo.svg"
                width="100"
              ></v-img>
              By installing this app you're agreeing with TestApp.io
              <br />
              <a
                class="white--text text-decoration-underline"
                href="https://testapp.io/privacy-policy"
                >Privacy Policy
              </a>
              &nbsp; and &nbsp;
              <a
                class="white--text text-decoration-underline"
                href="https://testapp.io/terms-and-conditions"
                >Terms &amp; Conditions</a
              >
            </div>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
    <div class="mt-5 mb-12 text-center">
      <div>
        <div class="mb-5">
          <a href="https://testapp.io">
            <img height="30" src="/media/logos/logo_dark.svg" />
          </a>
        </div>
        <div class="text-muted">
          By installing this app you're agreeing with TestApp.io
          <br />
          <a
            class="black--text text-decoration-underline"
            href="https://testapp.io/privacy-policy"
            >Privacy Policy
          </a>
          &nbsp; and &nbsp;
          <a
            class="black--text text-decoration-underline"
            href="https://testapp.io/terms-and-conditions"
            >Terms &amp; Conditions</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  GET_CURRENT_TIMESTAMP,
  GET_NEW_INSTALL_APP_INFO,
  VERIFY_INSTALL_LINK_PASSWORD,
} from "@/store/releases/install.module";
import { formatBytes, get, isEmpty } from "@/core/services/helper.service";
import ReleaseRequestForm from "@/view/components/Releases/ReleaseRequestForm";
import VueReadmore from "@/view/components/Common/VueReadmore";
import veeValidate from "@/mixins/veeValidate";
import SubscribeForm from "@/view/components/Releases/SubscribeForm";
import InstallQrCode from "@/view/components/Releases/InstallQrCode";
import { mapGetters } from "vuex";
import PermissionMapper from "@/view/components/Common/PermissionMapper";
import StorageService from "@/core/services/localstorage.service";

export default {
  components: {
    PermissionMapper,
    InstallQrCode,
    SubscribeForm,
    VueReadmore,
    ReleaseRequestForm,
  },
  mixins: [veeValidate],
  props: {
    fromExpress: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      platform: "android",
      openPermissionModal: false,
      passwordInformation: {},
      androidInstallConfirmation: false,
      subscribeEmail: "",
      subscribedMessage: "",
      protectedPassword: "",
      openIosInstallHelp: false,
      showPasswordModal: false,
      openSettingModal: false,
      openSubscribeModal: false,
      busy: false,
      qrcodeModal: false,
      formatBytes,
      isEmpty,
      form: {},
      readMore: false,
      get,
      slider: [
        "red",
        "green",
        "orange",
        "blue",
        "pink",
        "purple",
        "indigo",
        "cyan",
        "deep-purple",
        "light-green",
        "deep-orange",
        "blue-grey",
      ],
      mobile: "",
      copied: false,
      showRequestForm: false,
      verifyPasswordLoading: false,
      downloadProcess: false,
      loading: false,
      errorMessage: "",
      installInfo: {},
    };
  },
  computed: {
    ...mapGetters({
      isAuthenticated: "isAuthenticated",
    }),
    shareLink() {
      return `${location.origin}/apps/install/${this.$route?.params?.uid}`;
      // if (process.env.VUE_APP_ENV !== "production") {
      // } else {
      // return `https://testapp.io/apps/install/${this.$route.params.uid}`;
      // }
    },
    getAndroidReleaseInfo() {
      return this.installInfo.android;
    },
    getIOSReleaseInfo() {
      return this.installInfo.ios;
    },
    noReleases() {
      return (
        isEmpty(this.getAndroidReleaseInfo) && isEmpty(this.getIOSReleaseInfo)
      );
    },
    columns() {
      if (this.$vuetify.breakpoint.xl) {
        return 4;
      }

      if (this.$vuetify.breakpoint.lg) {
        return 3;
      }

      if (this.$vuetify.breakpoint.md) {
        return 2;
      }

      return 1;
    },
  },
  created() {
    if (window && window.Intercom) {
      window.Intercom("onShow", function () {
        if (this.$route.name === "install") {
          let launcher = document.querySelector(".intercom-launcher");
          launcher.style.display = "none";
        }
      });
    }

    this.mobile = this.getMobileOperatingSystem;
    this.getInstallAppInfo();
    this.$nextTick(() => {
      this.$options.metaInfo.title = this.installInfo.name;
    });
    //Hide intercom chat
    if (window && window.Intercom) {
      window.Intercom("hide");
      window.Intercom("update", {
        hide_default_launcher: true,
      });
    }
  },
  metaInfo() {
    return {
      title: `${
        this && this.installInfo ? this.installInfo.name || "Portal" : "Portal"
      } - Testapp.io`,
      meta: [
        {
          name: "description",
          content: `${
            this.getAppDescription()
              ? this.getAppDescription()?.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        {
          name: "og:image",
          content: `${this.getAppImage()}`,
        },
        {
          name: "og:description",
          content: `${
            this.getAppDescription()
              ? this.getAppDescription()?.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        {
          name: "twitter:description",
          content: `${
            this.installInfo && this.installInfo.description
              ? this.installInfo?.description?.replace(/\n/g, " ")
              : "App Description"
          }`,
        },
        // {
        //   name: "robots",
        //   content: "noindex,nofollow"
        // }
      ],
    };
  },
  methods: {
    getInstallAppInfo() {
      this.errorMessage = null;
      if (this.loading) return;
      this.loading = true;
      this.$store
        .dispatch(GET_NEW_INSTALL_APP_INFO, {
          id: this.$route?.params?.uid,
          token: window.sessionStorage.getItem("install_access_token"),
        })
        .then((response) => {
          this.loading = false;
          this.installInfo = response.data;

          this.installInfo.ios = isEmpty(this.installInfo.ios)
            ? null
            : this.installInfo.ios;
          this.installInfo.android = isEmpty(this.installInfo.android)
            ? null
            : this.installInfo.android;
        })
        .catch((err) => {
          this.loading = false;
          if (err.status === 423) {
            this.showPasswordModal = true;
            this.passwordInformation = {
              image: err.image,
              message: err.message,
              name: err.name,
            };
          }
          this.errorMessage = err.message;
        });
    },
    async verifyPassword() {
      if (await this.validateAllFields()) {
        if (this.verifyPasswordLoading) return;
        this.verifyPasswordLoading = true;
        this.$store
          .dispatch(VERIFY_INSTALL_LINK_PASSWORD, {
            id: this.$route.params?.uid,
            password: this.protectedPassword,
          })
          .then((response) => {
            this.verifyPasswordLoading = false;
            this.showPasswordModal = false;
            window.sessionStorage.setItem(
              "install_access_token",
              response.token
            );
            this.$nextTick(() => {
              this.getInstallAppInfo(response.token);
            });
          })
          .catch((err) => {
            this.verifyPasswordLoading = false;
            this.notifyErrorMessage(err.message);
          });
      }
    },
    setConfirmationInStorage() {
      StorageService.setItem("apk_install_terms_condition", true);
      this.androidInstallConfirmation = false;
      this.downloadApp();
    },
    showPermissionDialog() {
      this.openPermissionModal = true;
    },
    scrollToFAQ() {
      let elmnt = document.getElementById("releaseFaq");
      elmnt.scrollIntoView();
    },
    startDownload() {
      if (this.mobile === "android") {
        const confirmation = StorageService.getItem(
          "apk_install_terms_condition"
        );
        if (confirmation) {
          this.downloadApp();
        } else {
          this.androidInstallConfirmation = true;
        }
      } else {
        this.downloadApp();
      }
    },
    downloadApp() {
      if (this.downloadProcess) return;
      if (this.busy) return;
      this.busy = true;
      this.$store
        .dispatch(GET_CURRENT_TIMESTAMP)
        .then((response) => {
          this.busy = false;
          //@NOTE: DON'T CHANGE share_id to shareID
          const query = `?share_id=${this.$route.params?.uid}&t=${
            response.time || ""
          }`;
          if (this.mobile === "android") {
            window.open(this.getAndroidReleaseInfo.url + query, "_self");
            this.downloadProcess = true;
          }
          if (this.mobile === "ios") {
            if (this.getIOSReleaseInfo.testflight) {
              window.open(this.getIOSReleaseInfo.testflight, "_self");
            } else {
              window?.location?.replace(
                `itms-services://?action=download-manifest&url=${encodeURIComponent(
                  this.getIOSReleaseInfo.url + query
                )}`
              );
              setTimeout(() => {
                this.openIosInstallHelp = true;
              }, 2000);
              this.downloadProcess = true;
            }
          }
        })
        .catch((err) => {
          this.busy = false;
          this.notifyErrorMessage(err.message);
        });
    },
    getPlatformStatus() {
      if (get(this.installInfo, "settings.status", null)) {
        return false;
      } else {
        if (this.mobile === "android") {
          return this.getAndroidReleaseInfo;
        } else if (this.mobile === "ios") {
          return this.getIOSReleaseInfo;
        } else {
          return this.getAndroidReleaseInfo || this.getIOSReleaseInfo;
        }
      }
    },
    getAppDescription() {
      if (this.installInfo.description) {
        return this.installInfo.description;
      } else if (
        !get(this.installInfo, "settings.status", null) &&
        this.getPlatformStatus()
      ) {
        return "This app is ready for testing and waiting for your feedback!";
      } else {
        return "";
      }
    },
    getAppImage() {
      if (this.installInfo.image) {
        return this.installInfo.image;
      } else {
        if (this.getAndroidReleaseInfo && this.getAndroidReleaseInfo.image) {
          return this.getAndroidReleaseInfo.image;
        } else if (this.getIOSReleaseInfo && this.getIOSReleaseInfo.image) {
          return this.getIOSReleaseInfo.image;
        } else {
          return `${location.origin}/media/defaults/icon-100.png`;
        }
      }
    },
  },
};
</script>

<style scoped>
.errImg {
  max-width: 100%;
}

.screenShotContainer {
  overflow-x: auto;
  overflow-y: hidden;
  display: flex;
  white-space: nowrap;
}

/*.screenShotContainer {*/
/*  overflow-y: scroll;*/
/*  display: flex;*/
/*  height: 500px;*/
/*  white-space: nowrap;*/
/*  overflow-x: scroll;*/
/*  overflow-y: hidden;*/
/*  scrollbar-width: thin;*/
/*}*/
.scrollable {
  overflow-y: auto;
  -webkit-overflow-scrolling: unset;
  height: 470px;
}

.imageBorder {
  border-radius: 22px;
  margin-right: 24px;
  border: 0.5px solid rgba(0, 0, 0, 0.08);
}
</style>
